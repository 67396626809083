/* Site Header */
.site_header {
  @include wide-container;
  @include flex;
  @include flex-row-between;
  @include items-center;
  @include pt(4);
  @include mb(4);

  .-logo {
    @include h3-text;
    color: var(--primary-font-color);
    text-decoration: none;
    font-weight: 100;
    text-transform: lowercase;
    max-width: 160px;

    svg {
      max-width: 100%;
      height: auto;

      path {
        fill: var(--primary-font-color);
      }
    }
  }

  .-theme_switch {
    background: none;
    border: none;
    display: block;
    @include gap(2);

    svg {
      cursor: pointer;
      max-width: 28px;
      height: auto;
      transform-origin: center;
      will-change: transform;
      transition: transform 0.5s ease-in-out;
    
      &:hover {
        animation: spin-hover infinite linear;
      }
    
      &.no_animation {
        animation: none;
      }

    }

    svg g {
      fill: var(--primary-font-color);
    }
    transition: transform 0.5s ease-in-out;
  }
  
  .no_animation {
    animation: none !important;
  }
  
  @keyframes spin-hover {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
}

/* Homepage Article */
.homepage_article {
  @include container;
  @include pt(12);
  @include pb(2);

  h1 {
    @include h3-text;
    @include mb(4);
  }

  .-body {
    @include body-text;
    @include mb(6);
  }

  p {
    @include mb(4);
  }

}

/* Articles Feed */
.articles_feed {
  @include container;
  @include flex;
  @include flex-col;
  @include gap(6);
  @include mt(12);

  .-item {
    display: block;
    @include pt(4);
    @include pb(2);

    border-top: 12px solid var(--border-color);
    text-decoration: none;
    
    h2 {
      @include h3-text;
      @include mb(1);
      color: var(--secondary-font-color);
    }

    &:hover {
      .articles_feed .-item_read_more span {
        text-decoration: underline;
      }
    }
  }

  .-item_excerpt {
    @include body-text--small;
    color: var(--secondary-font-color);
  }

  .-item_meta {
    @include body-text--small;
    @include flex;
    @include flex-row-center;
    @include gap(1);
    font-weight: 500;
    color: var(--secondary-font-color);
    @include mb(4);
  }
  
  .-item_read_more {
    color: var(--secondary-font-color);
    @include alt-text;
    @include mt(3);
    @include mb(2);
    @include body-text--small;
    @include flex;
    @include flex-row-center;
    @include items-center;
    @include gap(1);
  }
  
  .-item_internal_icon,
  .-item_external_icon {
    display: block;
    margin-top: 4px;
    max-width: 22px;
    height: auto;
  }

  .-item_internal_icon {
  
    svg {
      fill: var(--secondary-font-color);
      max-width: 100%;
      height: auto;
    }
  }

  .-item_external_icon {

    svg {
      fill: var(--secondary-font-color);
      max-width: 100%;
      height: auto;
    }
  }
}

/* Header Background */
.header_background {

}

/* Article Header */
.article_header {
  @include wide-container;
  display: block;
  text-align: center;
  @include py(12);

  .-category {
    @include h5-text;
    color: var(--primary-font-color);
    @include mb(4);
  }

  .-title {
    @include page-title;
    color: var(--primary-font-color);
    @include pb(6);
  }

  .-meta {
    @include h5-text;
    color: var(--primary-font-color);
    @include pb(6);

  }
}

.social_profiles {
  @include container;

  @include flex;
  @include flex-row-center;
  @include gap(4);
  @include pb(12);

  a {
    color: var(--primary-font-color);
    text-decoration: none;
    @include pb(1);
    @include hover-border-bottom(var(--secondary-bg-color), 0.5s);
  }
}


/* Article Content */
.article_content {
  @include container;
  @include pt(12);

  &.--large {
    max-width: 720px;
  }


  // General Spacing for all direct children
  > * {
    margin-bottom: $spacing-lg;
  }

  // Specific Spacing Rules
  p {
    margin-bottom: $spacing-md;

    // Increase spacing after first paragraph
    &:first-of-type {
      margin-top: 0;
    }

    // Spacing between consecutive paragraphs
    & + p {
      margin-top: $spacing-sm;
    }
  }

  h1, h2, h3, h4, h5, h6 {
    margin-top: $spacing-lg;
    margin-bottom: $spacing-md;

    // Remove top margin if heading is the first child
    &:first-of-type {
      margin-top: 0;
    }
  }

  figure.image, iframe {
    display: block; // Ensure block display for images and iframes
    margin: $spacing-lg 0;
  }

  // Special case: if an image or iframe is followed by a paragraph or heading
  img + p,
  img + h1, img + h2, img + h3, img + h4, img + h5, img + h6,
  iframe + p,
  iframe + h1, iframe + h2, iframe + h3, iframe + h4, iframe + h5, iframe + h6 {
    margin-top: $spacing-md;
  }

  .image img {
    display: block;
    aspect-ratio: inherit !important;
    object-fit: contain;
  }
  
  // Adjust spacing for nested elements within lists
  ul {
    list-style-type: square;
  }

  ul, ol {
    margin-bottom: $spacing-md;
    padding-left: $spacing-md;

    li {
      margin-bottom: $spacing-sm;
    }
  }

  // Blockquotes
  blockquote {
    margin: $spacing-lg 0;
    padding: $spacing-sm $spacing-md;
    border-left: 4px solid #ccc;

    p {
      margin-bottom: $spacing-sm;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}


hr {
  border: 1px solid var(--border-color);
  margin: $spacing-lg 0;
}

/* Article Footer */
.article_footer {
  @include wide-container;

  img {
    border-radius: 14px;
    max-width: 100%;
    height: auto;
  }

  .-featured_image_caption {
    @include body-text--small;
    text-align: center;
    color: var(--secondary-font-color);
    @include mt(1);
    @include mb(6);
  }
}

/* Site Footer */
.site_footer {
  @include narrow-container;
  @include mt(20);
  @include mb(12);
  @include footer-text;
  text-align: center;
}

.icon {
  position: absolute; /* Ensure it starts with absolute positioning */
  transition: transform 0.5s ease-in-out, top 0.5s ease-in-out, left 0.5s ease-in-out;
}

.site_header .-logo.active {
  box-shadow: 0 0 15px rgba(255, 255, 255, 0.8);
}

pre {
  padding: 12px 10px;
  margin-bottom: $spacing-md;
  background-color: var(--primary-bg-color);
  color: var(--primary-font-color);
  border-radius: 3px;
  overflow-x: auto;
  scrollbar-width: thin;
  scrollbar-color: var(--primary-font-color) var(--primary-bg-color);
  line-height: 2;
  @include body-text--small;
}

pre::-webkit-scrollbar {
  height: 12px;
}

pre::-webkit-scrollbar-track {
  background: var(--secondary-font-color);
}

pre::-webkit-scrollbar-thumb {
  background-color: var(--primary-font-color);
  border-radius: 6px;
  border: 3px solid var(--secondary-font-color);
}

pre::-webkit-scrollbar-thumb:hover {
  background-color: var(--accent-font-color);
}

pre::-webkit-scrollbar:vertical {
  display: none;
}

/* Custom Theme Color Variables */
:root {
  --primary-bg-color: #2E3440;
  --primary-font-color: #FFFFFF;
  --comment-font-color: #7B8089;
  --class-font-color: #97C279;
}

/* General Code Styling */
code {
  padding: 2px 4px;
  background: var(--primary-bg-color);
  color: var(--primary-font-color);
  border-radius: 3px;
}

pre code {
  color: var(--primary-font-color);
  line-height: 1.8;
  padding: 0;
  background-color: transparent;
}

/* PrismJS Theme Configuration */
code[class*="language-"],
pre[class*="language-"] {
  color: var(--primary-font-color);
  background: var(--primary-bg-color);
  text-shadow: none;
}

/* Comments */
.token.comment,
.token.prolog,
.token.doctype,
.token.cdata {
  color: var(--comment-font-color);
  font-style: italic;
}

/* Class Names */
.token.class-name {
  color: var(--class-font-color);
  font-weight: bold;
}

/* Class Attributes */
.token.attr-value .token.punctuation {
  color: var(--primary-font-color);
}
.token.attr-value {
  color: var(--class-font-color);
}

/* General Tags, Attributes, and Built-ins */
.token.tag,
.token.attr-name,
.token.char,
.token.builtin,
.token.inserted {
  color: var(--primary-font-color);
}

/* Selectors and Strings */
.token.selector,
.token.string {
  color: var(--primary-font-color);
}

/* Line Numbers Styling */
.line-numbers .line-numbers-rows {
  border-right: 1px solid var(--comment-font-color);
}

.line-numbers-rows > span:before {
  color: var(--comment-font-color);
}

/* Text Selection */
code[class*="language-"]::-moz-selection,
code[class*="language-"] ::-moz-selection,
pre[class*="language-"]::-moz-selection,
pre[class*="language-"] ::-moz-selection,
code[class*="language-"]::selection,
code[class*="language-"] ::selection,
pre[class*="language-"]::selection,
pre[class*="language-"] ::selection {
  background: var(--comment-font-color);
  color: var(--primary-font-color);
}

/* Block Code Styling */
pre[class*="language-"] code {
  display: block;
  margin-bottom: 0.5em;
}

/* CSS-specific Styling */
code.language-css,
pre.language-css .token.selector {
  font-weight: bold;
}
