html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  border: 0;
  margin: 0;
  padding: 0;
}

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after, q:before, q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

:root {
  --primary-bg-color: #2b2f3a;
  --secondary-bg-color: #bdc2d9;
  --accent-bg-color: #1e1e1e;
  --primary-font-color: #bdc2d9;
  --secondary-font-color: #14161a;
  --accent-font-color: #fff;
  --border-color: #14161a;
  --primary-font: "EB Garamond", serif;
  --secondary-font: "IBM Plex Serif", serif;
  --font-weight-normal: 400;
  --font-weight-bold: 700;
  --h1-font-size: 2.5rem;
  --h1-line-height: 2.875rem;
  --h1-letter-spacing: .02em;
  --h2-font-size: 2rem;
  --h2-line-height: 2.375rem;
  --h2-letter-spacing: .02em;
  --h3-font-size: 1.75rem;
  --h3-line-height: 2rem;
  --h3-letter-spacing: .02em;
  --h4-font-size: 1.5rem;
  --h4-line-height: 1.75rem;
  --h4-letter-spacing: .015em;
  --h5-font-size: 1.25rem;
  --h5-line-height: 1.5rem;
  --h5-letter-spacing: .015em;
  --h6-font-size: 1rem;
  --h6-line-height: 1.25rem;
  --h6-letter-spacing: .01em;
  --body-font-size: 1.25rem;
  --body-line-height: 1.875rem;
  --body-letter-spacing: .01em;
  --body-large-font-size: 1.5rem;
  --body-large-line-height: 2.25rem;
  --body-large-letter-spacing: .01em;
  --body-small-font-size: 1rem;
  --body-small-line-height: 1.5rem;
  --body-small-letter-spacing: .01em;
  --small-font-size: .9rem;
  --small-line-height: 1.175rem;
  --small-letter-spacing: .01em;
  --caption-font-size: .9375rem;
  --caption-line-height: 1.25rem;
  --caption-letter-spacing: .005em;
  --container-max-width: 1200px;
  --wide-container-max-width: 1600px;
  --narrow-container-max-width: 800px;
  --flushed-container-max-width: 100%;
  --container-padding: 20px;
  --container-margin: 0 auto;
  --wide-container-margin: 40px;
}

@supports (color: lab(0% 0 0)) {
  :root {
    --primary-bg-color: lab(19.3502% .440687 -7.77966);
    --secondary-bg-color: lab(78.5787% 1.6472 -12.2736);
    --accent-bg-color: lab(11.26% .00000745058 -.00000298023);
    --primary-font-color: lab(78.5787% 1.6472 -12.2736);
    --secondary-font-color: lab(7.16437% -.132039 -3.29379);
    --accent-font-color: lab(100% 0 0);
    --border-color: lab(7.16437% -.132039 -3.29379);
  }
}

body {
  font-family: var(--secondary-font);
  font-size: var(--body-font-size);
  line-height: var(--body-line-height);
  font-weight: var(--font-weight-normal);
  letter-spacing: var(--body-letter-spacing);
  background: var(--secondary-bg-color);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1 {
  font-family: var(--primary-font);
  font-size: var(--h1-font-size);
  line-height: var(--h1-line-height);
  font-weight: var(--font-weight-bold);
  letter-spacing: var(--h1-letter-spacing);
}

h2 {
  font-family: var(--primary-font);
  font-size: var(--h2-font-size);
  line-height: var(--h2-line-height);
  font-weight: var(--font-weight-bold);
  letter-spacing: var(--h2-letter-spacing);
}

h3 {
  font-family: var(--primary-font);
  font-size: var(--h3-font-size);
  line-height: var(--h3-line-height);
  font-weight: var(--font-weight-bold);
  letter-spacing: var(--h3-letter-spacing);
}

h4 {
  font-family: var(--primary-font);
  font-size: var(--h4-font-size);
  line-height: var(--h4-line-height);
  font-weight: var(--font-weight-bold);
  letter-spacing: var(--h4-letter-spacing);
}

h5 {
  font-family: var(--primary-font);
  font-size: var(--h5-font-size);
  line-height: var(--h5-line-height);
  font-weight: var(--font-weight-normal);
  letter-spacing: var(--h5-letter-spacing);
}

h6 {
  font-family: var(--primary-font);
  font-size: var(--h6-font-size);
  line-height: var(--h6-line-height);
  font-weight: var(--font-weight-normal);
  letter-spacing: var(--h6-letter-spacing);
}

p {
  font-family: var(--secondary-font);
  font-size: var(--body-font-size);
  line-height: var(--body-line-height);
  font-weight: var(--font-weight-normal);
  letter-spacing: var(--body-letter-spacing);
}

blockquote {
  font-family: var(--secondary-font);
  font-size: var(--body-large-font-size);
  line-height: var(--body-large-line-height);
  font-weight: var(--font-weight-normal);
  letter-spacing: var(--body-large-letter-spacing);
  font-style: italic;
}

figure {
  font-family: var(--secondary-font);
  font-size: var(--body-font-size);
  line-height: var(--body-line-height);
  font-weight: var(--font-weight-normal);
  letter-spacing: var(--body-letter-spacing);
}

figcaption {
  font-family: var(--secondary-font);
  font-size: var(--caption-font-size);
  line-height: var(--caption-line-height);
  font-weight: var(--font-weight-normal);
  letter-spacing: var(--caption-letter-spacing);
}

img {
  max-width: 100%;
  height: auto;
}

a {
  font-family: var(--secondary-font);
  font-weight: var(--font-weight-bold);
  letter-spacing: var(--body-letter-spacing);
  text-decoration: underline;
}

a:hover {
  text-decoration: none;
}

strong {
  font-weight: var(--font-weight-bold);
}

i {
  font-style: italic;
}

footer {
  font-family: var(--secondary-font);
  font-size: var(--small-font-size);
  line-height: var(--small-line-height);
  font-weight: var(--font-weight-normal);
  letter-spacing: var(--small-letter-spacing);
}

.matrix--dusk_whisper_theme {
  --primary-bg-color: #2b2f3a;
  --secondary-bg-color: #bdc2d9;
  --accent-bg-color: #1e1e1e;
  --primary-font-color: #bdc2d9;
  --secondary-font-color: #14161a;
  --accent-font-color: #fff;
  --border-color: #14161a;
}

@supports (color: lab(0% 0 0)) {
  .matrix--dusk_whisper_theme {
    --primary-bg-color: lab(19.3502% .440687 -7.77966);
    --secondary-bg-color: lab(78.5787% 1.6472 -12.2736);
    --accent-bg-color: lab(11.26% .00000745058 -.00000298023);
    --primary-font-color: lab(78.5787% 1.6472 -12.2736);
    --secondary-font-color: lab(7.16437% -.132039 -3.29379);
    --accent-font-color: lab(100% 0 0);
    --border-color: lab(7.16437% -.132039 -3.29379);
  }
}

.matrix--lavender_sunset_theme {
  --primary-bg-color: #5b4269;
  --secondary-bg-color: #ebe4ce;
  --primary-font-color: #f8c02f;
  --secondary-font-color: #14161a;
  --border-color: #5b4269;
}

.matrix--neon_horizon_theme {
  --primary-bg-color: #f8c02f;
  --secondary-bg-color: #ede3cf;
  --primary-font-color: #5a4368;
  --secondary-font-color: #14161a;
  --border-color: #14161a;
}

.matrix--green_serenity_theme {
  --primary-bg-color: #546d46;
  --secondary-bg-color: #ebe4ce;
  --primary-font-color: #ebe4ce;
  --secondary-font-color: #14161a;
  --border-color: #14161a;
}

.matrix--mint_whisper_theme {
  --primary-bg-color: #a9c1b1;
  --secondary-bg-color: #fff;
  --primary-font-color: #243152;
  --secondary-font-color: #14161a;
  --border-color: #243152;
}

.matrix--azure_sky_theme {
  --primary-bg-color: #0083bb;
  --secondary-bg-color: #fff;
  --primary-font-color: #ebe3ce;
  --secondary-font-color: #243153;
  --border-color: #243153;
}

.matrix--ocean_twilight_theme {
  --primary-bg-color: #364b78;
  --secondary-bg-color: #a9c1b1;
  --primary-font-color: #a9c1b1;
  --secondary-font-color: #14161a;
  --border-color: #14161a;
}

.context--homepage .header_background, .context--article .header_background, .context--insights .header_background, .context--code_confessions .header_background {
  background-color: var(--primary-bg-color);
  color: var(--primary-font-color);
}

.site_header {
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  max-width: 1600px;
  margin: 0 auto 16px;
  padding: 16px 20px 0;
  display: flex;
}

.site_header .-logo {
  font-family: var(--primary-font);
  font-size: var(--h3-font-size);
  line-height: var(--h3-line-height);
  font-weight: var(--font-weight-bold);
  letter-spacing: var(--h3-letter-spacing);
  color: var(--primary-font-color);
  text-transform: lowercase;
  max-width: 160px;
  font-weight: 100;
  text-decoration: none;
}

.site_header .-logo svg {
  max-width: 100%;
  height: auto;
}

.site_header .-logo svg path {
  fill: var(--primary-font-color);
}

.site_header .-theme_switch {
  background: none;
  border: none;
  gap: 8px;
  transition: transform .5s ease-in-out;
  display: block;
}

.site_header .-theme_switch svg {
  cursor: pointer;
  transform-origin: center;
  will-change: transform;
  max-width: 28px;
  height: auto;
  transition: transform .5s ease-in-out;
}

.site_header .-theme_switch svg:hover {
  animation: linear infinite spin-hover;
}

.site_header .-theme_switch svg.no_animation {
  animation: none;
}

.site_header .-theme_switch svg g {
  fill: var(--primary-font-color);
}

.site_header .no_animation {
  animation: none !important;
}

@keyframes spin-hover {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

.homepage_article {
  max-width: 620px;
  margin: 0 auto;
  padding: 48px 20px 8px;
}

.homepage_article h1 {
  font-family: var(--primary-font);
  font-size: var(--h3-font-size);
  line-height: var(--h3-line-height);
  font-weight: var(--font-weight-bold);
  letter-spacing: var(--h3-letter-spacing);
  margin-bottom: 16px;
}

.homepage_article .-body {
  font-family: var(--secondary-font);
  font-size: var(--body-font-size);
  line-height: var(--body-line-height);
  font-weight: var(--font-weight-normal);
  letter-spacing: var(--body-letter-spacing);
  margin-bottom: 24px;
}

.homepage_article p {
  margin-bottom: 16px;
}

.articles_feed {
  flex-direction: column;
  gap: 24px;
  max-width: 620px;
  margin: 48px auto 0;
  padding: 0 20px;
  display: flex;
}

.articles_feed .-item {
  border-top: 12px solid var(--border-color);
  padding-top: 16px;
  padding-bottom: 8px;
  text-decoration: none;
  display: block;
}

.articles_feed .-item h2 {
  font-family: var(--primary-font);
  font-size: var(--h3-font-size);
  line-height: var(--h3-line-height);
  font-weight: var(--font-weight-bold);
  letter-spacing: var(--h3-letter-spacing);
  color: var(--secondary-font-color);
  margin-bottom: 4px;
}

.articles_feed .-item:hover .articles_feed .-item_read_more span {
  text-decoration: underline;
}

.articles_feed .-item_excerpt {
  font-family: var(--secondary-font);
  font-size: var(--body-small-font-size);
  line-height: var(--body-small-line-height);
  font-weight: var(--font-weight-normal);
  letter-spacing: var(--body-small-letter-spacing);
  color: var(--secondary-font-color);
}

.articles_feed .-item_meta {
  font-family: var(--secondary-font);
  font-size: var(--body-small-font-size);
  line-height: var(--body-small-line-height);
  font-weight: var(--font-weight-normal);
  letter-spacing: var(--body-small-letter-spacing);
  color: var(--secondary-font-color);
  flex-direction: row;
  align-items: center;
  gap: 4px;
  margin-bottom: 16px;
  font-weight: 500;
  display: flex;
}

.articles_feed .-item_read_more {
  color: var(--secondary-font-color);
  font-family: var(--primary-font);
  font-size: var(--body-font-size);
  line-height: var(--body-line-height);
  font-weight: var(--font-weight-normal);
  letter-spacing: var(--body-letter-spacing);
  font-family: var(--secondary-font);
  font-size: var(--body-small-font-size);
  line-height: var(--body-small-line-height);
  font-weight: var(--font-weight-normal);
  letter-spacing: var(--body-small-letter-spacing);
  flex-direction: row;
  align-items: center;
  gap: 4px;
  margin-top: 12px;
  margin-bottom: 8px;
  display: flex;
}

.articles_feed .-item_internal_icon, .articles_feed .-item_external_icon {
  max-width: 22px;
  height: auto;
  margin-top: 4px;
  display: block;
}

.articles_feed .-item_internal_icon svg, .articles_feed .-item_external_icon svg {
  fill: var(--secondary-font-color);
  max-width: 100%;
  height: auto;
}

.article_header {
  text-align: center;
  max-width: 1600px;
  margin: 0 auto;
  padding: 48px 20px;
  display: block;
}

.article_header .-category {
  font-family: var(--primary-font);
  font-size: var(--h5-font-size);
  line-height: var(--h5-line-height);
  font-weight: var(--font-weight-normal);
  letter-spacing: var(--h5-letter-spacing);
  color: var(--primary-font-color);
  margin-bottom: 16px;
}

.article_header .-title {
  font-family: var(--primary-font);
  letter-spacing: -.9px;
  color: var(--primary-font-color);
  padding-bottom: 24px;
  font-size: 2.8rem;
  font-weight: 100;
  line-height: 1.12;
}

.article_header .-meta {
  font-family: var(--primary-font);
  font-size: var(--h5-font-size);
  line-height: var(--h5-line-height);
  font-weight: var(--font-weight-normal);
  letter-spacing: var(--h5-letter-spacing);
  color: var(--primary-font-color);
  padding-bottom: 24px;
}

.social_profiles {
  flex-direction: row;
  align-items: center;
  gap: 16px;
  max-width: 620px;
  margin: 0 auto;
  padding: 0 20px 48px;
  display: flex;
}

.social_profiles a {
  color: var(--primary-font-color);
  padding-bottom: 4px;
  text-decoration: none;
  position: relative;
  overflow: hidden;
}

.social_profiles a:before {
  content: "";
  background-color: var(--secondary-bg-color);
  transform-origin: 0;
  width: 100%;
  height: 1px;
  transition: transform .5s ease-in-out;
  position: absolute;
  bottom: 0;
  left: 0;
  transform: scaleX(0);
}

.social_profiles a:hover:before {
  text-decoration: none;
  transform: scaleX(1);
}

.article_content {
  max-width: 620px;
  margin: 0 auto;
  padding: 48px 20px 0;
}

.article_content.--large {
  max-width: 720px;
}

.article_content > * {
  margin-bottom: 40px;
}

.article_content p {
  margin-bottom: 20px;
}

.article_content p:first-of-type {
  margin-top: 0;
}

.article_content p + p {
  margin-top: 10px;
}

.article_content h1, .article_content h2, .article_content h3, .article_content h4, .article_content h5, .article_content h6 {
  margin-top: 40px;
  margin-bottom: 20px;
}

.article_content h1:first-of-type, .article_content h2:first-of-type, .article_content h3:first-of-type, .article_content h4:first-of-type, .article_content h5:first-of-type, .article_content h6:first-of-type {
  margin-top: 0;
}

.article_content figure.image, .article_content iframe {
  margin: 40px 0;
  display: block;
}

.article_content img + p, .article_content img + h1, .article_content img + h2, .article_content img + h3, .article_content img + h4, .article_content img + h5, .article_content img + h6, .article_content iframe + p, .article_content iframe + h1, .article_content iframe + h2, .article_content iframe + h3, .article_content iframe + h4, .article_content iframe + h5, .article_content iframe + h6 {
  margin-top: 20px;
}

.article_content .image img {
  object-fit: contain;
  display: block;
  aspect-ratio: inherit !important;
}

.article_content ul {
  list-style-type: square;
}

.article_content ul, .article_content ol {
  margin-bottom: 20px;
  padding-left: 20px;
}

.article_content ul li, .article_content ol li {
  margin-bottom: 10px;
}

.article_content blockquote {
  border-left: 4px solid #ccc;
  margin: 40px 0;
  padding: 10px 20px;
}

.article_content blockquote p {
  margin-bottom: 10px;
}

.article_content blockquote p:last-child {
  margin-bottom: 0;
}

hr {
  border: 1px solid var(--border-color);
  margin: 40px 0;
}

.article_footer {
  max-width: 1600px;
  margin: 0 auto;
  padding: 0 20px;
}

.article_footer img {
  border-radius: 14px;
  max-width: 100%;
  height: auto;
}

.article_footer .-featured_image_caption {
  font-family: var(--secondary-font);
  font-size: var(--body-small-font-size);
  line-height: var(--body-small-line-height);
  font-weight: var(--font-weight-normal);
  letter-spacing: var(--body-small-letter-spacing);
  text-align: center;
  color: var(--secondary-font-color);
  margin-top: 4px;
  margin-bottom: 24px;
}

.site_footer {
  font-family: var(--secondary-font);
  font-size: var(--small-font-size);
  line-height: var(--small-line-height);
  font-weight: var(--font-weight-normal);
  letter-spacing: var(--small-letter-spacing);
  text-align: center;
  max-width: 360px;
  margin: 80px auto 48px;
  padding: 0 20px;
}

.icon {
  transition: transform .5s ease-in-out, top .5s ease-in-out, left .5s ease-in-out;
  position: absolute;
}

.site_header .-logo.active {
  box-shadow: 0 0 15px #fffc;
}

pre {
  background-color: var(--primary-bg-color);
  color: var(--primary-font-color);
  scrollbar-width: thin;
  scrollbar-color: var(--primary-font-color) var(--primary-bg-color);
  line-height: 2;
  font-family: var(--secondary-font);
  font-size: var(--body-small-font-size);
  line-height: var(--body-small-line-height);
  font-weight: var(--font-weight-normal);
  letter-spacing: var(--body-small-letter-spacing);
  border-radius: 3px;
  margin-bottom: 20px;
  padding: 12px 10px;
  overflow-x: auto;
}

pre::-webkit-scrollbar {
  height: 12px;
}

pre::-webkit-scrollbar-track {
  background: var(--secondary-font-color);
}

pre::-webkit-scrollbar-thumb {
  background-color: var(--primary-font-color);
  border: 3px solid var(--secondary-font-color);
  border-radius: 6px;
}

pre::-webkit-scrollbar-thumb:hover {
  background-color: var(--accent-font-color);
}

pre::-webkit-scrollbar:vertical {
  display: none;
}

:root {
  --primary-bg-color: #2e3440;
  --primary-font-color: #fff;
  --comment-font-color: #7b8089;
  --class-font-color: #97c279;
}

code {
  background: var(--primary-bg-color);
  color: var(--primary-font-color);
  border-radius: 3px;
  padding: 2px 4px;
}

pre code {
  color: var(--primary-font-color);
  background-color: #0000;
  padding: 0;
  line-height: 1.8;
}

code[class*="language-"], pre[class*="language-"] {
  color: var(--primary-font-color);
  background: var(--primary-bg-color);
  text-shadow: none;
}

.token.comment, .token.prolog, .token.doctype, .token.cdata {
  color: var(--comment-font-color);
  font-style: italic;
}

.token.class-name {
  color: var(--class-font-color);
  font-weight: bold;
}

.token.attr-value .token.punctuation {
  color: var(--primary-font-color);
}

.token.attr-value {
  color: var(--class-font-color);
}

.token.tag, .token.attr-name, .token.char, .token.builtin, .token.inserted, .token.selector, .token.string {
  color: var(--primary-font-color);
}

.line-numbers .line-numbers-rows {
  border-right: 1px solid var(--comment-font-color);
}

.line-numbers-rows > span:before {
  color: var(--comment-font-color);
}

code[class*="language-"]::-moz-selection {
  background: var(--comment-font-color);
  color: var(--primary-font-color);
}

code[class*="language-"] ::-moz-selection {
  background: var(--comment-font-color);
  color: var(--primary-font-color);
}

pre[class*="language-"]::-moz-selection {
  background: var(--comment-font-color);
  color: var(--primary-font-color);
}

pre[class*="language-"] ::-moz-selection {
  background: var(--comment-font-color);
  color: var(--primary-font-color);
}

code[class*="language-"]::selection, code[class*="language-"] ::selection, pre[class*="language-"]::selection, pre[class*="language-"] ::selection {
  background: var(--comment-font-color);
  color: var(--primary-font-color);
}

pre[class*="language-"] code {
  margin-bottom: .5em;
  display: block;
}

code.language-css, pre.language-css .token.selector {
  font-weight: bold;
}
/*# sourceMappingURL=styles.css.map */
