:root {
  /* Default Theme */
  --primary-bg-color: oklch(30.59% 0.021 269.44);
  --secondary-bg-color: oklch(81.74% 0.033 275.92);
  --accent-bg-color: oklch(23.5% 0 0);

  --primary-font-color: oklch(81.74% 0.033 275.92);
  --secondary-font-color: oklch(19.98% 0.009 264.36);
  --accent-font-color: oklch(100% 0 0);
  --border-color: oklch(19.98% 0.009 264.36);

  /* Typography */
  --primary-font: "EB Garamond", serif;
  --secondary-font: "IBM Plex Serif", serif;
  --font-weight-normal: 400;
  --font-weight-bold: 700;

  --h1-font-size: 2.5rem;
  --h1-line-height: 2.875rem;
  --h1-letter-spacing: 0.02em;

  --h2-font-size: 2rem;
  --h2-line-height: 2.375rem;
  --h2-letter-spacing: 0.02em;

  --h3-font-size: 1.75rem;
  --h3-line-height: 2.0rem;
  --h3-letter-spacing: 0.02em;

  --h4-font-size: 1.5rem;
  --h4-line-height: 1.75rem;
  --h4-letter-spacing: 0.015em;

  --h5-font-size: 1.25rem;
  --h5-line-height: 1.5rem;
  --h5-letter-spacing: 0.015em;

  --h6-font-size: 1rem;
  --h6-line-height: 1.25rem;
  --h6-letter-spacing: 0.01em;

  --body-font-size: 1.25rem;
  --body-line-height: 1.875rem;
  --body-letter-spacing: 0.01em;

  --body-large-font-size: 1.5rem;
  --body-large-line-height: 2.25rem;
  --body-large-letter-spacing: 0.01em;

  --body-small-font-size: 1.0rem;
  --body-small-line-height: 1.5rem;
  --body-small-letter-spacing: 0.01em;

  --small-font-size: 0.9rem;
  --small-line-height: 1.175rem;
  --small-letter-spacing: 0.01em;

  --caption-font-size: 0.9375rem;
  --caption-line-height: 1.25rem;
  --caption-letter-spacing: 0.005em;

  /* Container Defaults */
  --container-max-width: 1200px;
  --wide-container-max-width: 1600px;
  --narrow-container-max-width: 800px;
  --flushed-container-max-width: 100%;
  --container-padding: 20px;
  --container-margin: 0 auto;
  --wide-container-margin: 40px;
}

/* Typography Mixins */
@mixin page-title {
  font-family: var(--primary-font);
  font-size: 2.8rem;
  line-height: 1.12;
  font-weight: 100;
  letter-spacing: -0.9;
}

@mixin h1-text {
  font-family: var(--primary-font);
  font-size: var(--h1-font-size);
  line-height: var(--h1-line-height);
  font-weight: var(--font-weight-bold);
  letter-spacing: var(--h1-letter-spacing);
}

@mixin h2-text {
  font-family: var(--primary-font);
  font-size: var(--h2-font-size);
  line-height: var(--h2-line-height);
  font-weight: var(--font-weight-bold);
  letter-spacing: var(--h2-letter-spacing);
}

@mixin h3-text {
  font-family: var(--primary-font);
  font-size: var(--h3-font-size);
  line-height: var(--h3-line-height);
  font-weight: var(--font-weight-bold);
  letter-spacing: var(--h3-letter-spacing);
}

@mixin h4-text {
  font-family: var(--primary-font);
  font-size: var(--h4-font-size);
  line-height: var(--h4-line-height);
  font-weight: var(--font-weight-bold);
  letter-spacing: var(--h4-letter-spacing);
}

@mixin h5-text {
  font-family: var(--primary-font);
  font-size: var(--h5-font-size);
  line-height: var(--h5-line-height);
  font-weight: var(--font-weight-normal);
  letter-spacing: var(--h5-letter-spacing);
}

@mixin h6-text {
  font-family: var(--primary-font);
  font-size: var(--h6-font-size);
  line-height: var(--h6-line-height);
  font-weight: var(--font-weight-normal);
  letter-spacing: var(--h6-letter-spacing);
}

@mixin body-text {
  font-family: var(--secondary-font);
  font-size: var(--body-font-size);
  line-height: var(--body-line-height);
  font-weight: var(--font-weight-normal);
  letter-spacing: var(--body-letter-spacing);
}

@mixin body-text--large {
  font-family: var(--secondary-font);
  font-size: var(--body-large-font-size);
  line-height: var(--body-large-line-height);
  font-weight: var(--font-weight-normal);
  letter-spacing: var(--body-large-letter-spacing);
}

@mixin body-text--small {
  font-family: var(--secondary-font);
  font-size: var(--body-small-font-size);
  line-height: var(--body-small-line-height);
  font-weight: var(--font-weight-normal);
  letter-spacing: var(--body-small-letter-spacing);
}

@mixin alt-text {
  font-family: var(--primary-font);
  font-size: var(--body-font-size);
  line-height: var(--body-line-height);
  font-weight: var(--font-weight-normal);
  letter-spacing: var(--body-letter-spacing);
}

@mixin small-text--small {
  font-family: var(--secondary-font);
  font-size: var(--small-font-size);
  line-height: var(--small-line-height);
  font-weight: var(--font-weight-normal);
  letter-spacing: var(--small-letter-spacing);
}

@mixin caption-text {
  font-family: var(--secondary-font);
  font-size: var(--caption-font-size);
  line-height: var(--caption-line-height);
  font-weight: var(--font-weight-normal);
  letter-spacing: var(--caption-letter-spacing);
}

@mixin link-text {
  font-family: var(--secondary-font);
  text-decoration: underline;
  font-weight: var(--font-weight-bold);
  letter-spacing: var(--body-letter-spacing);
  
  &:hover {
    text-decoration: none;
  }
}

@mixin footer-text {
  font-family: var(--secondary-font);
  font-size: var(--small-font-size);
  line-height: var(--small-line-height);
  font-weight: var(--font-weight-normal);
  letter-spacing: var(--small-letter-spacing);
}

/* Applying Mixins to HTML Elements */
body {
  @include body-text;
  background: var(--secondary-bg-color);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1 {
  @include h1-text;
}

h2 {
  @include h2-text;
}

h3 {
  @include h3-text;
}

h4 {
  @include h4-text;
}

h5 {
  @include h5-text;
}

h6 {
  @include h6-text;
}

p {
  @include body-text;
}

blockquote {
  @include body-text--large;
  font-style: italic;
}

figure {
  @include body-text;
}

figcaption {
  @include caption-text;
}

img {
  max-width: 100%;
  height: auto;
}

a {
  @include link-text;
}

strong {
  font-weight: var(--font-weight-bold);
}

i {
  font-style: italic;
}

footer {
  @include footer-text;
}

@mixin hover-border-bottom($color: #000, $duration: 0.5s, $height: 1px) {
  position: relative;
  overflow: hidden;

  &:before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: $height;
    background-color: $color;
    transform: scaleX(0);
    transform-origin: left;
    transition: transform $duration ease-in-out;
  }

  &:hover:before {
    transform: scaleX(1);
    text-decoration: none;
  }
}
