/*
    this.themeNames = [
      "matrix--dusk_whisper_theme",
      "matrix--lavender_sunset_theme",
      "matrix--neon_horizon_theme",
      "matrix--green_serenity_theme",
      "matrix--mint_whisper_theme",
      "matrix--azure_sky_theme",
      "matrix--ocean_twilight_theme"
    ];
*/

.matrix {
  &--dusk_whisper_theme {
    --primary-bg-color: oklch(30.59% 0.021 269.44);
    --secondary-bg-color: oklch(81.74% 0.033 275.92);
    --accent-bg-color: oklch(23.5% 0 0);
  
    --primary-font-color: oklch(81.74% 0.033 275.92);
    --secondary-font-color: oklch(19.98% 0.009 264.36);
    --accent-font-color: oklch(100% 0 0);
    --border-color: oklch(19.98% 0.009 264.36);
  }

  &--lavender_sunset_theme {
    --primary-bg-color: #5B4269;
    --secondary-bg-color: #EBE4CE;

    --primary-font-color: #F8C02F;
    --secondary-font-color: #14161A;
    --border-color: #5B4269;
  }

  &--neon_horizon_theme {
    --primary-bg-color: #F8C02F;
    --secondary-bg-color: #EDE3CF;

    --primary-font-color: #5A4368;
    --secondary-font-color: #14161A;
    --border-color: #14161A;
  }

  &--green_serenity_theme {
    --primary-bg-color: #546D46;
    --secondary-bg-color: #EBE4CE;

    --primary-font-color: #EBE4CE;
    --secondary-font-color: #14161A;
    --border-color: #14161A;
  }

  &--mint_whisper_theme {
    --primary-bg-color: #A9C1B1;
    --secondary-bg-color: #FFFFFF;

    --primary-font-color: #243152;
    --secondary-font-color: #14161A;
    --border-color: #243152;
  }

  &--azure_sky_theme {
    --primary-bg-color: #0083BB;
    --secondary-bg-color: #FFFFFF;

    --primary-font-color: #EBE3CE;
    --secondary-font-color: #243153;
    --border-color: #243153;
  }

  &--ocean_twilight_theme {
    --primary-bg-color: #364B78;
    --secondary-bg-color: #A9C1B1;

    --primary-font-color: #A9C1B1;
    --secondary-font-color: #14161A;
    --border-color: #14161A;
  }
}
